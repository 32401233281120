import {
  // customDispatcher,
  defaultDocumentPrefixes,
  formulateItems,
  logMemo,
  logRender,
  truncate,
} from "../../../utils";
import {useNavigate, useParams} from "react-router-dom";
import { PRIMARY_COLOR, states } from "../../../models/constants";
// import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  List, InputNumber,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  getLedgers,
  getVouchers,
  getVouchersByLedgerId,
  loginRequest,
  saveVoucher,
} from "../../../services/api";
import {trackEvent, EMpEventName} from "../../../services/analytics/mixpanel";
import moment from "moment";
import { NavBar } from "antd-mobile";
import { Switch } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SaveOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {isMobileApp} from "../../../utils/webviewUtils";
import Text from "../../components/Text";
import StackActivity from "../../components/StackActivity";
import styled from "styled-components";
import {DocumentNumber} from "./DocumentNumber";
import {DateUI} from "./DateUI";
import {ItemsComponent} from "./ItemsComponent";
import BottomPopup from "../../components/BottomPopup";
import {RUPEE} from "../../../models/constants";
import {useLazyStoreData} from "../../../hooks/useLazyStoreData.hook";
import {StoreDataTypes} from "../../../hooks/useStoreDataLoader.hook";
import {useStoreDataLoader} from "../../../hooks/useStoreDataLoader.hook";
import {CustomerBlock} from "./CustomerBlock";
import ActivityWrapper from "../../components/Activity";
import {ELayerTrigger, ELayerType, useLayersFramework, useMyParams} from "../../v2framework/framework.hooks";
import {nonPreviewVoucherTypes, voucherTypes} from "../../../models/jsConstants";
import {EnumLayer} from "../../v2framework/layers.list";

const VoucherWriteActivity = React.memo(() => {
  logRender("NewVoucher");
  // return "NewVoucher2.1";
  const [, params] = useMyParams();
  const {goBack} = useLayersFramework();
  const navigate = useNavigate();
  console.log("## voucherWriteParams ##", params);
  const {voucherType, voucherId} = params || {};
  const docType = voucherTypes[voucherType] || {};
  const isNewVoucher = !voucherId;
  const [form] = Form.useForm();
  const [voucher, setVoucher] = useState({});
  const [company] = useLazyStoreData(StoreDataTypes.COMPANY);
  console.log("## defaultDocNumNumber Company ##", company);
  const defaultDocNumNumber = _.get(company, `autoIncrement.${voucherType}`, 1);
  console.log("## defaultDocNumNumber ##", defaultDocNumNumber);
  const [allLedgers] = useLazyStoreData(StoreDataTypes.LEDGERS);
  const [allItems] = useLazyStoreData(StoreDataTypes.ITEMS);
  const [syncData] = useStoreDataLoader();

  console.log("## LazyData ##", {
    company,
    allLedgers,
    allItems,
  })
  const ledgersMap = useMemo(() => {
    return _.keyBy((allLedgers || []).filter(
        (l) =>
            !l.type ||
            l.type ===
            (voucherType === "purchase" || voucherType === "debitNote"
                ? "creditor"
                : "debtor")
    ), "_id")
  }, [allLedgers, voucherType]);
  const [selectedLedgerId, setSelectedLedgerId] = useState();
  const [voucherItems, setVoucherItems] = useState([]);
  const [flagFetchVoucher, setFlagFetchVoucher] = useState(false);

  const [editClientDetail, setEditClientDetail] = useState(false);
  const {pushInAppLayer} = useLayersFramework();
  const [creditNoteInvoices, setCreditNoteInvoices] = useState([]);
  const [showSelectInvoiceModal, setShowSelectInvoiceModal] = useState(false);
  const [selectedInvoiceValue, setSelectedInvoiceValue] = useState("");
  const [isRoundOffEnabled, setRoundOffEnabled] = useState(true);
  // const dispatch = customDispatcher(useDispatch());
  

  const dbItemsMap = useMemo(() => {
    return _.keyBy(allItems, "_id");
  }, [allItems]);

  const selectedLedger = ledgersMap[selectedLedgerId] || {};

  const {
    // tableItems,
    summary: { subtotal, totalCgst, totalIgst, roundedTotal, roundedOff },
  } = useMemo(() => {
    logMemo("formulateItems");
    return formulateItems(
      voucherItems,
      dbItemsMap,
      selectedLedger?.state !== _.get(company, "state"),
      isRoundOffEnabled,
    );
  }, [voucherItems, dbItemsMap, selectedLedger?.state, company, isRoundOffEnabled]);

  const getText = (text) => {
    return (
      <Text
        content={text}
        fontWeight={500}
        fontSize={12}
        color="gray"
        style={{ padding: "1px 15px" }}
      />
    );
  };

  useEffect(() => {
    form.setFieldsValue(voucher);
  }, [voucher, form]);

  useEffect(() => {
    console.log("## useEffect defaultDocNumNumber ##", defaultDocNumNumber);
    if (defaultDocNumNumber) {
      form.setFieldsValue({
        documentNumber: {
          prefix: docNumPrefix,
          num: defaultDocNumNumber
        }
      });
    }
  }, [defaultDocNumNumber]);

  useEffect(() => {
    if (company) {
      console.log("## State changed fetching ledgers ##");
      setFlagFetchVoucher(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (["creditNote", "debitNote"].includes(voucherType) && selectedLedgerId) {
      InvoiceValue("");
      getVouchersByLedgerId(
        selectedLedgerId,
        voucherType === "creditNote" ? "invoice" : "purchase"
      ).then((resp) =>
        setCreditNoteInvoices(
          resp.data.data.vouchers.map((data) => ({
            label:
              voucherType === "creditNote"
                ? data.displayDocNum
                : data.externalDocumentNumber,
            value: data._id,
          }))
        )
      );
    }

    console.log("useeeeee");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLedgerId, voucherType]);

  console.log("useeeeee", creditNoteInvoices);

  const autoIncValue = _.get(company, `autoIncrement.${voucherType}`, 1);
  const docNumPrefix = _.get(company, `invoiceConfig.prefixes.${voucherType}`, defaultDocumentPrefixes[voucherType]);

  useEffect(() => {
    let _voucher = {};

    if (!flagFetchVoucher) {
      return;
    }
    if (company) {
      if (isNewVoucher) {
        // new voucher mode
        const newVoucherDefaults = {
          date: moment(),
          // documentNumber: _.get(state, `company.autoIncrement.${voucherType}`),
          items: [],
        };



        if (docType.idType === "mixed") { // currently only this will be the case always
          newVoucherDefaults.documentNumber = {
            prefix: docNumPrefix,
            num: autoIncValue
          };
        } else {
          newVoucherDefaults.documentNumber = "999"; // code will never come here currently
        }

        setVoucher(newVoucherDefaults);
      } else {
        // edit mode
        getVouchers("notRequired", voucherId).then((res) => {
          console.log("## res ##", res);
          _voucher = _.get(res, "data.data.vouchers[0]");
          _voucher.date = moment(_voucher.date);
          if (!_voucher.items) {
            _voucher.items = [];
          }
          setVoucher(_voucher);
          setVoucherItems(_voucher.items);
          setSelectedLedgerId(_voucher.ledgerDr);
        });
      }
    }
  }, [flagFetchVoucher, isNewVoucher, voucherId, autoIncValue, docNumPrefix, voucherType, company]);

  const InvoiceValue = (value, label = "") => {
    setSelectedInvoiceValue(label);
    form.setFieldsValue({ originalInvoice: value });
  };

  const SelectInvoiceContent = (
    <OuterContainer>
      <PopUpHeader>
        <TopLine />
        Select Invoice
      </PopUpHeader>
      {
        <>
          <div style={{ padding: 6, background: "#fff" }}>
            <ListContainer>
              <List>
                {creditNoteInvoices.map(
                  (i) =>
                    i.label && (
                      <Item
                        onClick={() => InvoiceValue(i.value, i.label)}
                        selected={i.label === selectedInvoiceValue}
                      >
                        <List.Item
                          arrow={false}
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          {i.label}
                        </List.Item>
                      </Item>
                    )
                )}
              </List>
            </ListContainer>
          </div>
        </>
      }
    </OuterContainer>
  );

  return (
      <ActivityWrapper heading={<span>{isNewVoucher ? "Create" : "Edit"} {docType.label || docType.name}</span>}>

      <div>
        <Form
          form={form}
          layout="horizontal"
          name="form_in_modal"
          initialValues={voucher}
          onValuesChange={(changedValues) => {
            if (changedValues.ledgerDr) {
              setSelectedLedgerId(changedValues.ledgerDr);
            }
          }}
        >
          <div
            style={{
              padding: 8,
              width: "100%",
              height: "70%",
              border: "0px solid blue",
              overflow: "scroll",
              position: "absolute",
              top: 60,
              bottom: 76,
            }}
          >
            <Row
              style={{
                marginBottom: 4,
                border: "1px solid lightgray",
                padding: "10px",
                background: "#eeeefa",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "55%",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Col
                  style={{
                    verticalAlign: "middle",
                    fontWeight: 600,
                  }}
                >
                  #
                </Col>
                <Col style={{ border: "0px solid green" }}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name= {["purchase", "debitNote"].includes(voucherType) ? "externalDocumentNumber" : "documentNumber"}
                    rules={[
                      {
                        required: true,
                        message: "can't be blank",
                        validator: (rule, value, cb) => {
                          console.log("## Validator ##", value);
                          if (["number", "text"].includes(docType.idType)) {
                            if (!value) {
                              cb("can't be blank");
                            }
                            cb();
                            return;
                          }
                          if (!value || !value.num) {
                            cb("can't be blank");
                          } else {
                            if (!value.prefix) {
                              value.prefix = "";
                            }
                            cb();
                          }

                          // value.length < 3 ? cb("too short") : cb();
                        },
                      },
                    ]}
                  >
                    {
                      docType.idType === "mixed" ?
                          <DocumentNumber/> :
                          (docType.idType === "text" ?
                                  <Input placeholder={docType.idTitle}/>
                                  : <Number placeholder={docType.idTitle} min={1}/>
                          )
                    }
                    {/*<DocumentNumber type={voucherType} />*/}
                  </Form.Item>
                </Col>
              </div>
              <Col span={!isMobileApp() && 9} style={{ display: "flex", justifyContent: "flex-end", width: "45%"}} >
                <Form.Item
                  name={"date"}
                  rules={[{ required: true, message: "can't be blank" }]}
                  style={{ marginBottom: 0 }}
                >
                  {isMobileApp() ? (
                    <DateUI />
                  ) : (
                    <DatePicker format={"DD MMM YYYY"} allowClear={false} />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row
              style={{
                border: "1px solid lightgray",
                padding: "10px",
                background: "#fff8e1",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <Col span={24}>
                <h3 style={{ color: "#737171" }}>
                  {voucherType === "invoice"
                    ? "Customer"
                    : voucherType === "purchase" || voucherType === "debitNote"
                    ? "Vendor"
                    : "Customer"}
                </h3>
                <Form.Item
                  name={"ledgerDr"}
                  rules={[
                    { required: true, message: "Please select party" },
                  ]}
                  style={{ marginBottom: 8 }}
                >
                  <CustomerBlock/>
                </Form.Item>
                {selectedLedgerId && (
                  <div
                    style={{ color: "grey", lineHeight: "15px", fontSize: 12 }}
                  >
                    <Row>
                      <Col span={2}>
                        <EditOutlined
                          style={{ fontSize: 25, color: PRIMARY_COLOR }}
                          onClick={() => {
                            // setEditClientDetail(true);
                            pushInAppLayer({
                              trigger: ELayerTrigger.IN_APP,
                              type: ELayerType.FULL_SCREEN,
                              target: EnumLayer.SAVE_LEDGER,
                            }, {
                              ledgerSaveTargetVendorType: selectedLedger?.type,
                              ledgerSaveSelectedRecord: selectedLedger,
                              ledgerSaveOnSave: () => {},
                            })
                          }}
                        />
                      </Col>
                      <Col span={22}>
                        {truncate(selectedLedger?.address, 90)}
                        <br />
                        {states["IN"][selectedLedger?.state]?.name || "--"}
                        {selectedLedger.phone
                          ? ` | ${selectedLedger.phone}`
                          : ""}
                        {selectedLedger.email
                          ? ` | ${selectedLedger.email}`
                          : ""}
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
            {["creditNote", "debitNote"].includes(voucherType) && (
              <Row
                style={{
                  border: "1px solid lightgray",
                  padding: "10px",
                  background: "#e0f7fa",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "2%",
                    marginBottom: "15px",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <h3 style={{ color: "#737171" }}>Invoice :</h3>
                    <Form.Item
                      name={"originalInvoice"}
                      style={{ marginBottom: -8, marginTop: 8 }}
                    >
                      <SelectedClient
                        onClick={() => setShowSelectInvoiceModal(true)}
                      >
                        {selectedInvoiceValue ? (
                          selectedInvoiceValue
                        ) : (
                          <Text
                            color="#c0c0c0"
                            fontSize={18}
                            content="Select Invoice"
                          />
                        )}
                      </SelectedClient>
                    </Form.Item>
                  </div>
                  <div style={{ width: "52%" }}>
                    <h3 style={{ color: "#737171" }}>Reason :</h3>
                    <Form.Item
                      name={"reason"}
                      style={{ marginBottom: -8, marginTop: 8 }}
                    >
                      <ReasonInput
                        placeholder={`e.g. ${
                          voucherType === "creditNote" ? "Sales" : "Purchase"
                        } Return`}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Form.Item
                  name={"items"}
                  rules={[
                    {
                      required: true,
                      message: "Document must have at least one item line",
                    },
                  ]}
                >
                  <ItemsComponent
                    dbItemsMap={dbItemsMap}
                    isIgst={
                      selectedLedger?.state !== _.get(company, "state")
                    }
                    onChange={setVoucherItems}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <BottomSection>
            <InlineContainer>
              {getText(" Subtotal:")}
              {getText(`${RUPEE}${subtotal}`)}
            </InlineContainer>
            <InlineContainer>
              {selectedLedger?.state !== _.get(company, "state") ? (
                <>
                  {getText(" IGST:")}
                  {getText(totalIgst > 0 ? `${RUPEE}${totalIgst}` : "---")}
                </>
              ) : (
                <>
                  {getText(" CGST + SGST:")}
                  {getText(totalCgst > -1
                      ? `${RUPEE}${2*totalCgst}`
                      : "---"
                  )}
                </>
              )}
            </InlineContainer>
            <InlineContainer>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {getText(" Rounded Off:")}
                <Form.Item
                  name={"enableRoundOff"}
                  style={{ margin: 0, padding: 0, marginLeft: "5px", marginBottom: "5px" }}
                >
                  <Switch
                    defaultChecked={true}
                    checkedChildren={"On"}
                    unCheckedChildren={"Off"}
                    onChange={(val) => setRoundOffEnabled(val)}
                    style={{ background: isRoundOffEnabled ? "green" : "red" }}
                    valuesmap={{
                      true: true,
                      false: false,
                    }}
                    reversemap={{
                      true: true,
                      false: false,
                    }}
                    size={"small"}
                  />
                </Form.Item>
              </div>
              {getText(`${RUPEE}${roundedOff}`)}
            </InlineContainer>
            <InlineContainer background topBorder height="60px">
              <div>
                <Text
                  content={"Total Amount: "}
                  fontWeight={500}
                  fontSize={14}
                />
                <Text
                  content={`${RUPEE}${roundedTotal}`}
                  fontWeight={600}
                  fontSize={18}
                />
              </div>
              <button
                className={
                  "fab primaryEffect generalRoundedButton buttonProperties"
                }
                style={{
                  height: "40px",
                  width: "35%",
                  marginBottom: "-24px",
                  left: "20px",
                }}
                onClick={async () => {
                  // const clientVals = await formClient.validateFields();
                  const obj = await form.validateFields();

                  if (!isNewVoucher) {
                    obj._id = voucher._id;
                  }
                  obj.amount = roundedTotal;
                  obj.type = voucherType;
                  if (obj.type === undefined) {
                    obj.enableRoundOff = true;
                  }

                  console.log("ob", obj);
                  const result = await saveVoucher({
                    voucher: obj,
                    client: {
                      inputMode: "existing",
                      values: {
                        _id: obj.ledgerDr,
                      },
                    },
                  });
                  if (_.get(result, "data.success")) { //
                    const vid = isNewVoucher
                      ? _.get(result, "data.data.voucher._id")
                      : voucher._id;
                    message.success("Document Saved successfully!");
                    trackEvent(EMpEventName.FEATURE_USED, {
                      featureName: isNewVoucher ? "Create Voucher" : "Edit Voucher",
                      voucherType,
                    });
                    await syncData(StoreDataTypes.COMPANY);
                    if (nonPreviewVoucherTypes.includes(voucherType)) {
                      goBack();
                    } else {
                      navigate(`/vouchers/${voucherType}/view/${vid}`);
                    }
                  } else {
                    message.error(_.get(result, "data.message"));
                  }
                }}
              >
                <SaveOutlined />
                &nbsp;Save
              </button>
            </InlineContainer>
          </BottomSection>
        </Form>
      </div>

      {/*{editClientDetail && (*/}
      {/*  <StackActivity*/}
      {/*    visible={editClientDetail}*/}
      {/*    setVisible={setEditClientDetail}*/}
      {/*    type="updateCustomer"*/}
      {/*    data={selectedLedger}*/}
      {/*  />*/}
      {/*)}*/}

      <BottomPopup
        content={SelectInvoiceContent}
        height={
          creditNoteInvoices.length > 5
            ? "400px"
            : `${90 + creditNoteInvoices.length * 50}px`
        }
        visible={showSelectInvoiceModal}
        setVisible={setShowSelectInvoiceModal}
      />

      </ActivityWrapper>
  );
});

export default VoucherWriteActivity;

const SelectedClient = styled.div`
  height: 40px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 2px solid #d0caca4a;
  margin-top: 4px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
`;

const ReasonInput = styled(Input)`
  height: 40px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 2px solid #d0caca4a;
  margin-top: 4px;
  font-size: 18px;
`;

const OuterContainer = styled.div``;

const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
  padding-left: 10px;
  border-bottom: 1px solid #d0caca4a;
`;

const ListContainer = styled.div`
  max-height: 310px;
  overflow-y: scroll;
  border-top: 2px solid #d0caca4a;
`;

const BottomSection = styled.div`
  height: ${({ height }) => height};
  width: 100%;
  bottom: 0px;
  position: absolute;
  background: white;
  display: flex;
  border: 2px solid #d0caca4a;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: ${({ noSpace }) =>
    noSpace ? "flex-start" : "space-between"};
  gap: ${({ noSpace }) => noSpace && "10px"};
  padding: ${({ padding }) => (padding ? "4px 15px" : "0px 15px")};
  border-top: ${({ topBorder }) => topBorder && "2px solid lightgray"};
  background: ${({ background }) => (background ? "white" : "#d3d3d34d")};
  height: ${({ height }) => height? height : "20px"};
  align-items: center;
`;


const Number = styled(InputNumber)`
width: 45%;
border-top-right-radius: 12px !important;
border-bottom-right-radius: 12px !important;
height: 40px;
padding-top: 4px;

.ant-input-number-input {
    font-weight: 600;
}
`;

