import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import { Divider, Form } from "antd";
import _ from "lodash";
import { taxes, units } from "../../../../models/constants";
import { loginRequest, saveItem } from "../../../../services/api";
import {customDispatcher, errorMessage, logRender} from "../../../../utils";
import { useDispatch } from "react-redux";
import { UploadImage } from "../../../components/UploadImage";
import Activity from "../../../components/Activity";
import { SwitchSelect } from "../../../components/Switch";
import BottomSelectFormComponent from "../../../components/BottomSelect";
import { RUPEE } from "../../../../models/constants";
import { TextArea,Input } from "antd-mobile";
import { CustomInput } from "../../../../styles/js/styleConstants";
import {MySelectPopup} from "../../../components/MySelectPopup";
import {Z_INDEXES} from "../../../../styles/js/z_indexes";
import {StoreDataTypes, useStoreDataLoader} from "../../../../hooks/useStoreDataLoader.hook";
import {appConfig} from "../../../../config";
import { useLayersFramework } from "../../../v2framework/framework.hooks";
import { deleteItem } from "../../../../services/api";
import { Dialog } from "antd-mobile";
const ItemSave = ({ }) => {
  const { setDropdownOpen, goBack, args: layerArgs } = useLayersFramework();
  const selectedRecord = layerArgs.saveItemActivityArgs?.selectedItem;
  const onSave = layerArgs.saveItemActivityArgs?.onSave;
  const onCancel = goBack;


  logRender("ItemSave");
  const dispatch = customDispatcher(useDispatch());
  const [syncData] = useStoreDataLoader();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (selectedRecord) {
      form.setFieldsValue({
        type: selectedRecord.type || "Product",
        unit: selectedRecord.unit || "NOS",
      });
    } else {
      form.setFieldsValue({
        type: "Product",
        unit: "NOS",
      });
    }
  }, [selectedRecord, form]);

  const itemOptions = [
    { label: "Product", value: "Product" },
    { label: "Service", value: "Service" },
  ];


  const unitOptions = useMemo(() => {
    return  _.map(units, ({ code, name }) => ({
      label: `${name} (${code})`,
      value: code,
    }))
  }, []);

  const taxRateoptions = _.map(taxes, ({ name, rate }) => ({
    label: name,
    value: rate,
  }));

  return (
      <div>
    <Activity
      heading={selectedRecord?._id ? "Update Item" : "Add New Item"}
      onBack={() => {
        onCancel();
      }}
      content={
        <>
          <div style={{ padding: "5% 5% 15%" }}>
            <Form
              form={form}
              labelCol={{
                span: 5,
                style: {
                  textAlign: "left",
                },
              }}
              wrapperCol={{ span: 13 }}
              layout="horizontal"
              name="form_in_modal"
              initialValues={selectedRecord || {}}
            >
              <Form.Item
                name="name"
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>Name</p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please write Item Name",
                  },
                ]}
              >
                <Input placeholder={"Item Name"} className='myTextInput'/>
              </Form.Item>
              <Form.Item
                name={"type"}
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>Type</p>
                }
                initialValue={"Product"}
              >
                <MySelectPopup
                options={itemOptions}
                />
              </Form.Item>
              <Form.Item
                name={"unit"}
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>Unit</p>
                }
                rules={[{ required: true, message: "Please select Unit" }]}
              >
                <BottomSelectFormComponent  
                  header="Select Unit"
                  optionsList={unitOptions}
                  searchPlaceholder="Search with Unit Name"
                  zIndex={Z_INDEXES.ITEM_SAVE_SELECT_UNIT}
                  setDropdownOpen={setDropdownOpen}
                />
              </Form.Item>
              <Form.Item
                name={"taxRate"}
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>
                    Tax Rate{" "}
                  </p>
                }
                initialValue={"18"}
                rules={[{ required: true, message: "Please select Tax Rate" }]}
              >
                <MySelectPopup
                    options={taxRateoptions}
                />
              </Form.Item>
              <Form.Item
                name="hsn"
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>
                    HSN / SAC Code
                  </p>
                }
              >
                <Input type={"number"} placeholder={"HSN / SAC Code"} maxLength={10} className='myTextInput'/>
              </Form.Item>
              <Form.Item
                name="defaultRate"
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>
                    Selling Price (per unit)
                  </p>
                }
              >
                <Input type={"number"} prefix={`${RUPEE}`}placeholder={"Default Selling Price"} min={0} className='myTextInput'/>
              </Form.Item>
              <Form.Item
                name={"defaultDescription"}
                label={
                  <p style={{ fontSize: "16px", marginTop: "20px" }}>
                    Default Description
                  </p>
                }
              >
                <TextArea
                // style={{
                //   border: "2px solid #d0caca4a",
                //   borderRadius: 12,
                //   padding: 4,
                // }}
                className="myTeaxtAreaInput"
                placeholder="Default Item Description"
                autoSize={{ minRows: 2, maxRows: 2 }}
              />
              </Form.Item>
              {
                appConfig.enableEcommerce &&
                  <>
                    <Divider />
                    <h2>Ecommerce Store Settings</h2>
                    <Form.Item
                        name={"showInEcommerceStore"}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><div><p style={{ fontSize: "16px", marginTop: "20px" }}>
                        Show in Ecommerce Store
                      </p></div>
                        <SwitchSelect
                            defaultChecked={true}
                            checkedChildren={"Show"}
                            unCheckedChildren={"Don't Show"}
                            valuesmap={{
                              true: true,
                              false: false,
                            }}
                            reversemap={{
                              true: true,
                              false: false,
                            }}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                        name={"img"}
                        label={
                          <p style={{ fontSize: "16px", marginTop: "20px" }}>
                            Product Image
                          </p>
                        }
                    >
                      <UploadImage />
                    </Form.Item>
                  </>
              }

            </Form>
          </div>
          <ButtonContainer>
            {selectedRecord?._id && (
              <button
                className={"fab dangerEffect generalRoundedButton buttonProperties"}
                style={{ backgroundColor: '#ff4d4f', color: 'white' }}
                onClick={async () => {
                  // Delete handler will be added later
                  console.log('Delete clicked');
                  Dialog.confirm({
                    content: `Are you sure you want to delete ${selectedRecord.name}?`,
                        closeOnMaskClick: true,
                        confirmText: "Yes",
                        cancelText: "No",
                        onConfirm: async () => {
                          // API integration will be added later
                          console.log("Delete clicked for:", selectedRecord);
                          const res = await deleteItem(selectedRecord._id);
                          if (res.data.success) {
                            onCancel();
                          } else {
                            errorMessage(res.data.message || "Failed to delete item");
                          }
                        }
                  });
                }}
              >
                Delete Item
              </button>
            )}
            <button
              className={"fab primaryEffect generalRoundedButton buttonProperties"}
              onClick={async () => {
                form
                  .validateFields()
                  .then(async (values) => {
                    if (!values.defaultRate) {
                      values.defaultRate = 0;
                    }
                    if (!values.defaultDescription) {
                      values.defaultDescription = "";
                    }
                    if (selectedRecord?._id) {
                      values._id = selectedRecord._id;
                    }
                    try {
                      await saveItem(values);
                      onSave(values);
                      onCancel();
                      console.log("## Dispatching loginRequest ##");
                      await loginRequest("", "", dispatch);
                      await syncData(StoreDataTypes.ITEMS);
                    } catch (err) {
                      errorMessage("Failed to save item");
                      console.error("Failed to save user", err);
                    }
                  })
                  .catch((info) => {
                    console.error("## Validate Failed ##", info);
                  });
              }}
            >
              Save Item
            </button>
          </ButtonContainer>
        </>
      }
    />
      </div>
  );
};

export default ItemSave;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -2px;
  position: absolute;
  background: white;
  height: 70px;
  margin-left: 10px;
  padding: 20px 20px 0 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  button {
    flex: 1;
    min-width: 0;
  }
`;

