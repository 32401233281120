import axios from 'axios';
import {setCredentials} from "./cookiesPlus";
import {EStorageKeys, iStorage} from "./storage";
import {setMpUserIdentity, setMpUserAttributes, setMpCommonArgs} from "./analytics/mixpanel";


const externalApiInstance = axios.create({
    timeout: 7000
})

const hostname = window.location.hostname;
const origin = window.location.origin;

export let baseUrl;
export let paymentBaseUrl;

if (hostname.includes("localhost"))  { // localhost without nginx, use api-server from local
  baseUrl = "http://localhost:4001/intelli";
} else if (hostname.includes("apihost") || hostname.includes("192.")) { // localhost without nginx, use api-server from staging
    baseUrl = "https://staging.khatabuddy.com/api";
    baseUrl = "http://192.168.1.14:4001/intelli";
    paymentBaseUrl = "https://staging.khatabuddy.com";
} else { // prod or (local using nginx)
  baseUrl = `${origin}/api`;
  paymentBaseUrl = `${origin}`;
}

export const exportedUrls = {
  baseUrl,
  origin,
}

axios.interceptors.request.use( (config) => {
  // const token = store.getState().session.token;
  // config.headers.Authorization =  token;
    if (!config.auth?.username) { // default auth not set in axios, will try to fetch from cookies
        const creds = iStorage.get(EStorageKeys.credentials);
        const currentPass = creds?.password;
        const currentUser = creds?.username;
    config.auth = {
      username: currentUser || "",
      password: currentPass || "",
        }
  }
  return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error?.response?.status === 401) {
      setCredentials("", "");
      // message.error("Authentication Failed: Incorrect credentials");
      if (!window.location.pathname.includes("login")) {
        window.location = "/login";
      }
      return;
    }
    return error;
});


const axiosV2 = axios.create();
axiosV2.interceptors.response.use(response => response.data, err => err);

export default axios;

export const requestOtpV2 = async (data) =>
    axiosV2.post(`${baseUrl}/auth/internal/requestOtp`, data);

export const verifyLoginV2 = async (data) =>
    axiosV2.post(`${baseUrl}/auth/internal/verifyLogin`, data);



export const loginRequest = async (username, password, dispatch) => {
  try {
      console.log(`Base_URL`, baseUrl);
        const response = await axios.get(`${baseUrl}/appData`, (username ? {
            auth: {
                username, password
          }
        } : undefined));

    if (response?.status === 200 && username) {
            axios.defaults.auth = {username, password};
      setCredentials(username, password);
    }

    const appDashboardBanners = response.data.data.miscData.appDashboardBanners;
    const user = response.data.data.user;
    if (user) { // logged in
      const { company, items } = user;
      user.redirectToOnboarding = !company?.name;
      // delete user.company;
      delete user.items;

      setMpUserIdentity(user._id);
      setMpUserAttributes({
        name: user.name,
        email: user.email,
        phone: user.phone,
      });
      const getCompanyAge = (_id) => {
        try {
          return Math.floor((Date.now() - parseInt(_id.substring(0,8), 16) * 1000) / (1000 * 60 * 60 * 24));
        } catch(err) {
          console.error("Error in getting company age", err);
          return 0;
        }
      }
      
      setMpCommonArgs({
        platform: "mobileApp",
        companyName: company?.name || "--",
        companyId: company?._id || "--",
        companyAge: getCompanyAge(company?._id),
      });

      dispatch({ user, company, items, appDashboardBanners });
    }
    return user;
  } catch (err) {
        console.error('Authentication Failed - Invalid credentials');
  }
}

export const signup = async (data) => {
  return axios.post(`${baseUrl}/internal/signup`, data);
}

export const googleLogin = async (data) => {
  return axios.post(`${baseUrl}/internal/googleLogin`, data);
}

export const sendOTP = async (data) => {
  return axios.post(`${baseUrl}/internal/sendOTP`, data);
}

export const forgotPassword = async (data) => {
  return axios.post(`${baseUrl}/internal/forgotPassword`, data);
}

export const saveUser = async (data) => {
  const newPassword = data?.user?.password;
  const resp = await axios.post(`${baseUrl}/user`, data);
  if (newPassword) {
    axios.defaults.auth.password = newPassword;
    setCredentials(axios.defaults.auth.username, newPassword);
  }
  return resp;
}

export const internalSaveUser = async (data) => {
  const newPassword = data?.user?.password;
  const resp = await axios.post(`${baseUrl}/internal/saveUser`, data);
  if (newPassword) {
    axios.defaults.auth = {
      username: data?.user?.email,
            password: data?.user?.password
    };
    setCredentials(data?.user?.email, newPassword);
  }
  return resp;
}

export const getSubusers = async () => {
  return axios.get(`${baseUrl}/subusers`);
}

export const getVoucherPdf = async (voucherId) => {
  return fetch(`${baseUrl}/internal/pdf/${voucherId}`, {
        method: 'GET',
    headers: {
            'Content-Type': 'application/pdf',
    },
  });
}

export const exportReports = async (data) => {
  return fetch(`${baseUrl}/internal/export?` + new URLSearchParams(data), {
        method: 'GET',
    headers: {
            'Content-Type': 'application/zip',
    },
  });
}

export const emailInvoice = async (data) => {
  return axios.post(`${baseUrl}/emailInvoice`, data);
}

export const deleteSubuser = async (userIndex) => {
  return axios.delete(`${baseUrl}/subuser/${userIndex}`);
}

export const addSubuser = async (data) => {
  return axios.post(`${baseUrl}/subuser`, data);
}

export const getLinkInfo = async (key) => {
  return axios.get(`${baseUrl}/internal/linkInfo/${key}`);
}

export const submitHelpTicket = async (data) => {
  return axios.post(`${baseUrl}/helpTicket`, data);
}

export const getAllData = async () =>
    axios.get(`${baseUrl}/data/all`);

export const getStoreDataItems = async () =>
    axios.get(`${baseUrl}/data/items`);

export const getLedgers = (type = "debtor") => {
  return axios.get(`${baseUrl}/ledgers?type=${type}`);
}

export const saveLedger = (ledger) => {
    return axios.post(`${baseUrl}/ledger`, {ledger});
}

export const deleteLedger = (id) => {
  return axios.delete(`${baseUrl}/ledger/${id}`);
}

export const getVouchers = (type, id) => {
  return axios.get(`${baseUrl}/vouchers?type=${type}&id=${id || ""}`);
}

export const getInternalVouchers = (id) => {
  return axios.get(`${baseUrl}/internal/vouchers?id=${id || ""}`);
}

export const getSummary = () => {
  return axios.get(`${baseUrl}/summary`);
}

export const getGreetingsData = () => {
  return axios.get(`${baseUrl}/misc/greetings`);
}

export const getInternalCompany = (id) => {
  return axios.get(`${baseUrl}/internal/company/${id}`);
}

export const fetchBankAccountWithIFSCCode = (ifscCode) =>
  externalApiInstance.get(`https://ifsc.razorpay.com/${ifscCode}`);

export const saveVoucher = (data) => {
  return axios.post(`${baseUrl}/voucher`, data);
}

export const saveCompany = (obj) => {
    return axios.post(`${baseUrl}/company`, {company: obj});
}

export const deleteVoucher = (id) => {
  return axios.delete(`${baseUrl}/voucher/${id}`);
}

export const deleteItem = (id) => {
  return axios.delete(`${baseUrl}/item/${id}`);
}

export const saveItem = (item) => {
    return axios.post(`${baseUrl}/item`, {item});
}

export const getBanners = () => {
  return axios.get(`${baseUrl}/banners`);
}

export const saveBanner = (bannerData) => {
    return axios.post(`${baseUrl}/banners`, {bannerData});
}

export const getActivations = () => {
  return axios.get(`${baseUrl}/activations`);
}

export const saveActivation = (activationData) => {
    return axios.post(`${baseUrl}/activations`, {activationData});
}

export const stockInOut = (data) => {
    return axios.post(`${baseUrl}/inventory/addRecord`, {record : data});
}

export const getInternalLedgerStatement = (ledgerId, from, to) => {
    return axios.get(`${baseUrl}/internal/statement?ledgerId=${ledgerId}&from=${from}&to=${to}`);
}

export const fetchEcommerceSummary = ()=> axios.get(`${baseUrl}/ecommerce/summary`);

export const fetchOrders = ()=> axios.get(`${baseUrl}/ecommerce/orders`);

export const fetchOrderBasedOnOrderId = (orderId)=> axios.get(`${baseUrl}/ecommerce/internal/order/${orderId}`);

export const createEcommerceOrder = (order)=> axios.post(`${baseUrl}/ecommerce/internal/order`, {ecommerceOrder:order});

export const getTransactions = () => {
  return axios.get(`${baseUrl}/pg/transactions`);
};

export const getPayouts = () => {
  return axios.get(`${baseUrl}/pg/payouts`);
};

export const getGreetingImages = (data) => {
    return `${baseUrl}/misc/greetings/internal/getCustomDesign?` + new URLSearchParams(data);
}

export const getVouchersByLedgerId = (ledgerId, type) =>
  axios.get(`${baseUrl}/vouchersByLedgerId/${ledgerId}?type=${type}`);

export const getPlans = () => axios.get(`${baseUrl}/getPlans`);

export const getCoupons = () => axios.get(`${baseUrl}/coupons`);
  
export const applyCoupon = (data) => axios.post(`${baseUrl}/applyCoupon`, data);
  
export const completePurchase = (data) => axios.post(`${baseUrl}/completePurchase`, data);

export const checkPaymentStatus = (orderId) => axios.get(`${baseUrl}/internal/payOrder?orderId=${orderId}`);

export const gstinValidation = (gstin) => axios.post(`${baseUrl}/misc/validateGst/`, {"gstin":gstin});

export const sendSMS = (data,type) =>
    axios.post(`${baseUrl}/private/sendSMS?type=${type}`, data);
