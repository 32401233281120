import React, { useState, useEffect } from 'react';
import { AndroidOutlined, CloseOutlined } from '@ant-design/icons';
import playStoreIcon from '../../res/img/playStoreLink.png';
import {isMobileApp} from "../../utils/webviewUtils";

export const PlayStoreNudge = () => {
    const [isAndroid, setIsAndroid] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Check if user agent contains Android
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsAndroid(userAgent.includes('android')  && !isMobileApp());
    }, []);

    if (!isAndroid || !isVisible) return null;

    const handleClick = () => {
        window.open("https://play.google.com/store/apps/details?id=com.khatabuddy.app", "_blank");
    };

    const handleClose = (e) => {
        e.stopPropagation(); // Prevent triggering the parent's onClick
        setIsVisible(false);
    };

    return (
        <div 
            onClick={handleClick}
            style={{
                padding: '10px 16px',
                background: 'linear-gradient(135deg, #1677ff 0%, #4096ff 100%)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0 2px 8px rgba(22, 119, 255, 0.2)',
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 10,
                overflow: 'hidden',
                cursor: 'pointer',
                transition: 'transform 0.2s ease, opacity 0.2s ease',
                ':hover': {
                    opacity: 0.95
                }
            }}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'radial-gradient(circle at 30% 107%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.05) 55%, rgba(255, 255, 255, 0) 70%)'
            }}/>
            <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '12px',
                zIndex: 1,
                flex: 1
            }}>
                <AndroidOutlined style={{ 
                    fontSize: '24px', 
                    color: 'white',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                }} />
                <div style={{ 
                    fontWeight: '500',
                    fontSize: '15px',
                    textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}>
                    Get our Android app
                </div>
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                zIndex: 1
            }}>
                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#000',
                        borderRadius: '6px',
                        border: '1px solid rgba(255,255,255,0.2)',
                        height: '40px',
                        width: '135px',
                    }}
                >
                    <img 
                        src={playStoreIcon} 
                        alt="Get it on Google Play"
                        style={{
                            height: '100%',
                            width: '100%',
                            pointerEvents: 'none'
                        }}
                    />
                </div>
                <div 
                    onClick={handleClose}
                    style={{
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        background: 'rgba(255,255,255,0.1)',
                        transition: 'background 0.2s ease',
                        ':hover': {
                            background: 'rgba(255,255,255,0.2)'
                        }
                    }}
                >
                    <CloseOutlined style={{ fontSize: '14px', color: 'white' }} />
                </div>
            </div>
        </div>
    );
}; 