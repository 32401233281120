import React, {useEffect} from "react";
import {logRender} from "../../utils";
import {LayersRegistry} from "./registry";
import {ELayerType, useLayersFramework} from "./framework.hooks";
import {PlayStoreNudge} from "../components/PlayStoreNudge";
const SubComponent = React.memo(({currentLayers}) => {
    logRender("SubComponent", currentLayers);

    return (
        <div className={"noselect"} style={{fontFamily: 'sans-serif'}}>
            <div style={{width: '100vw', height: '100vh', background: 'white'}}>
                {
                    currentLayers.map((layer, index) => {
                        // const isLast = index === totalLayersCount - 1;
                        return (
                            <div style={{
                                display: layer.shouldDisplay ? 'block' : 'none',
                                // width: '100vh', height: '100vw',
                                // position: 'fixed', top: 0, left: 0
                            }} key={`layerStack_${index}`}>
                                {LayersRegistry[layer.target] || `Layer ${layer.target} not registered`}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
})

export const AppIndexPage = React.memo(() => {
    // const [activities] = useMyParams();
    const {currentLayers} = useLayersFramework();
    logRender("AppIndexPage", currentLayers);
    return (
        <>
            <PlayStoreNudge />
            {/*// <>App Index Page</>*/}
            <SubComponent currentLayers={currentLayers}/>
        </>
    )
});