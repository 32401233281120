import React, {useEffect, useState} from "react";
import {Row, Col, Form} from "antd";
import {
    CheckCircleOutlined,
    UserOutlined,
    LockOutlined,
    ArrowRightOutlined,
    CloseCircleOutlined, MailOutlined,
    BuildOutlined
} from "@ant-design/icons"
import {useDispatch} from "react-redux";
import {customDispatcher, isBlankString, logRender, sleep} from "../../utils";
import {forgotPassword, loginRequest, requestOtpV2, sendOTP, verifyLoginV2} from "../../services/api";
import imgOtp from "../../res/img/otp.png";
import {trackEvent, EMpEventName} from "../../services/analytics/mixpanel";

import {
    Divider,
    Image,
    Input,
    Button,
    NavBar, Grid, Dialog, Loading
} from "antd-mobile";
import logo from "../../res/img/logoLandscapeV3.png";
import {emitAppLoadedSignal} from "../../utils/webviewUtils";
import { LockFill, PhoneFill, SmileOutline} from "antd-mobile-icons";
import InputDigits from "../inputDigits";
import {getCredentials} from "../../services/cookiesPlus";
import {useNavigate} from "react-router-dom";
import { MobileButtonV2 } from "../components/MobileButtonV2";

const OTP_WAITING_TIME = 30;

const communicationModesMap = {
    phone: {
        text: "number",
        displayUsername: (username) => `+91-${username}`,
        title: "Edit Phone or Email",
    },
    email: {
        text: "email",
        displayUsername: (username) => username,
        title: "Edit Phone or Email",
    }
}

// inputs:: loginData (communicationMode, username), onDone, onBack
const VerifyOTP = ({
                       loginData, onDone, onBack,
                       //                    originalOtp = "", phone = "", onDone = () => {
                       // }, onBack = () => {
                       // }
                   }) => {

    const [timeLeft, setTimeLeft] = useState(OTP_WAITING_TIME);
    const [processingText, setProcessingText] = useState(false);
    const [errOtp, setErrOtp] = useState(false);
    const [success, setSuccess] = useState(false);
    const [disabledKeyboard, setDisabledKeyboard] = useState(false);

    useEffect(() => {
        // message.success(`OTP : ${originalOtp}`);
        setTimeLeft(OTP_WAITING_TIME);
    }, [loginData]);

    useEffect( () => {
        const effectHandler = async () => {
            if (timeLeft > 0) {
                // console.log("## DECR ##");
                await new Promise(resolve => setTimeout(resolve, 1000));
                setTimeLeft(timeLeft - 1);
            }
        }

        effectHandler();
    }, [timeLeft]);

    const mode = communicationModesMap[loginData.communicationMode] || {}

    return (
        <div>
            <NavBar back={mode.title} onBack={onBack} style={{background: '#1677ff', color: 'white'}}
                // backArrow={<ArrowLeftOutlined/>}
            />
            <div style={{textAlign: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <br/>
                    <Image src={imgOtp} style={{height: 100, width: 100}}/>
                    <br/>
                    <h1><strong>Verify OTP</strong></h1>
                    {/*<br/>*/}
                    <p style={{color: 'grey'}}>We have sent an OTP on your {mode.text} <br/>{mode.displayUsername?.(loginData.username)}</p>
                </div>
                <InputDigits
                    style={{padding: 20}}
                    disabledKeyboard={disabledKeyboard || success}
                    onFill={async (val) => {
                        try {
                            setProcessingText("Verifying OTP");
                            const {username} = loginData;
                            console.log("## Username/otp ##", username, typeof val, val);
                            const {success, message: msg, data} = await verifyLoginV2({
                                username,
                                inputOtp: parseInt(val),
                            });
                            await sleep(500);

                            if (!success) {
                                throw new Error(msg);
                            }


                            let method;
                            if (username?.includes?.("@")) {
                                method = "Email OTP";
                            } else if (username?.length === 10) {
                                method = "Phone OTP";
                            } else {
                                method = "Unknown";
                            }
                            
                            trackEvent(EMpEventName.LOGIN, {
                                method,
                            });
                            setErrOtp(false);
                            setSuccess(true);
                            await onDone(data);

                        } catch(err) {
                            setErrOtp(true);
                            setSuccess(false);
                        } finally {
                            setProcessingText(undefined);
                        }
                    }}
                    length={4}
                    cell={(digit, index) => {
                        return <div style={{
                            width: 60,
                            height: 60,
                            borderRadius: 100,
                            background: digit ? '#1677ff' : 'lightgrey',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            lineHeight: '60px',
                            color: 'white',
                            // fontWeight: 'bold',
                            fontSize: 40,
                        }}>{digit}</div>
                    }}
                />
                {
                    success &&
                    <div style={{color: '#00b578', fontSize: 24}}>

                        Thanks for verification&nbsp;<SmileOutline/>
                        <br/>
                        <Loading color='currentColor'/>
                    </div>
                }
                {
                    !success && errOtp &&
                    <p style={{color: 'red'}}>You have entered an incorrect OTP</p>
                }
                {
                    !success && (
                        processingText ?
                            <div style={{color: '#00b578', fontSize: 24}}>
                                <span style={{fontSize: 18}}>{processingText}</span>
                                <Loading color='currentColor'/>
                            </div>
                            :
                            <div>
                                <div style={{color: 'grey'}}>Don't receive an OTP ?</div>
                                <a to={"#"} style={{}} onClick={async () => {
                                    if (timeLeft > 0) {
                                        return;
                                    }
                                    setDisabledKeyboard(true);
                                    setProcessingText("Resending OTP");
                                    await requestOtpV2({username: loginData.username}); // resend OTP
                                    await new Promise(resolve => setTimeout(resolve, 1500));
                                    setProcessingText(undefined);
                                    setTimeLeft(OTP_WAITING_TIME);
                                    setErrOtp(false);
                                    Dialog.show({
                                        onClose: () => {
                                            setDisabledKeyboard(false);
                                        },
                                        closeOnAction: true,
                                        header: (
                                            <CheckCircleOutlined
                                                style={{
                                                    fontSize: 64,
                                                    color: 'var(--adm-color-success)',
                                                }}
                                            />
                                        ),
                                        title: 'OTP resent successfully',
                                        actions: [
                                            {key: 'online', text: 'Okay'}
                                        ],
                                        closeOnMaskClick: true,
                                    })
                                }}><b><u>{timeLeft > 0 ? `Resend in ${timeLeft} seconds` : "Resend OTP"}</u></b></a>
                            </div>
                    )
                }
            </div>

        </div>
    )
}


const LoginComponent = ({setView, loginData, setLoginData}) => {
    const [username, setUsername] = useState("");
    const [sendingOtp, setSendingOtp] = useState(false);

    useEffect(() => {
        if (loginData?.username) {
            setUsername(loginData?.username);
        }
    }, [loginData])

    return (
        <div>
            <div style={{width: '100%', textAlign: 'left', border: '0px solid red', padding: 12}}>
                <Image style={{width: 150}} src={logo}/>
            </div>
            <Divider style={{margin: 0}}/>
            <div style={{padding: 16, textAlign: 'center'}}>
                <h2><strong>Login using OTP</strong></h2>
                <p style={{paddingLeft: 20, paddingRight: 20, color: 'grey'}}>Please enter your 10-digit mobile number or email address, we will send you an OTP to verify</p>

                <Form>
                    <Grid columns={10} style={{
                        width: '100%',
                        border: '3px solid #1677ff',
                        borderRadius: 8,
                        fontSize: 20,
                        padding: 8
                    }}>
                        <Grid.Item span={10}>
                            <Input
                                value={username}
                                onChange={setUsername}
                                maxLength={60}
                                placeholder={"Mobile or Email"}
                                style={{
                                    '--font-size': 'current',
                                    paddingRight: 12,
                                }}/>
                        </Grid.Item>
                    </Grid>
                    <br/>
                    {
                        !sendingOtp &&
                        <MobileButtonV2 color={"primary"} htmlType={"submit"} onClick={async () => {
                            try {
                                setSendingOtp(true);
                                if (!username) {
                                    throw new Error(`invalid username`);
                                }

                                const {success, message, data: {communicationMode}} = await requestOtpV2({username});
                                console.log("## Success ##", success, message);
                                if (!success) {
                                    throw new Error("API FAILED");
                                }

                                setLoginData({
                                    username,
                                    communicationMode,
                                })

                                setView('verifyOtp');

                            } catch(err) {
                                Dialog.show({
                                    closeOnAction: true,
                                    header: (
                                        <CloseCircleOutlined
                                            style={{
                                                fontSize: 64,
                                                color: 'var(--adm-color-danger)',
                                            }}
                                        />
                                    ),
                                    title: 'Invalid Mobile or Email',
                                    actions: [
                                        {key: 'online', text: 'Okay'}
                                    ],
                                    // closeOnMaskClick: true,
                                });
                            } finally {
                                setSendingOtp(false);
                            }
                        }} style={{padding: 0, margin: 0, border: 0, width: '100%', height: 50}}>
                            <>
                            {/*<Button htmlType="submit" style={{width: '100%', height: 50}} color={"primary"}*/}
                            {/*>*/}
                                Send OTP <ArrowRightOutlined/>
                            {/*</Button>*/}
                            </>
                        </MobileButtonV2>
                    }
                </Form>
                {
                    sendingOtp &&
                    <div style={{color: '#00b578', fontSize: 16}}>
                        <br/>
                        <div style={{fontSize: 24}}>Awesome&nbsp;<SmileOutline/></div>
                        Let us send you an OTP
                        <Loading style={{fontSize: 22}} color='currentColor'/>
                    </div>
                }
            </div>

        </div>
    )
}

const backwardCompatibleDefaultViews = ["login", "loginWithPassword", "signup", "reset"];

export const LoginActivity = React.memo(() => {
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const [view, setView] = useState('login');
    const [loginData, setLoginData] = useState({});

    window.nativeApi.setView = setView;


    useEffect(() => {
        const {username, password} = getCredentials();
        if (username && password) {
            // goBack(); ToDo infinity
        }
    }, []);

    return (
        <div>
            <Row style={{height: '100vh', padding: 0, margin: 0}}>
                <Col span={24} style={{}}>
                    {
                        backwardCompatibleDefaultViews.includes(view) &&
                        <LoginComponent
                            setView={setView}
                            loginData={loginData}
                            setLoginData={setLoginData}
                        />
                    }
                    {
                        view === "verifyOtp" &&
                        <VerifyOTP
                            loginData={loginData}
                            setView={setView}
                            onDone={async (data) => {
                                const {username, token} = data;
                                console.log("## Logging in with ##", {username, token});
                                const user = await loginRequest(username, token, dispatch);
                                await new Promise(resolve => setTimeout(resolve, 1500));
                                if (user && user.redirectToOnboarding) { // logged in

                                    // pushActivity(EActivity.LAST_STEP);
                                    navigate('/lastStep');
                                } else if (user) {
                                    if (!isBlankString(user.company?.name)) {
                                        // pushActivity(EActivity.HOME);
                                        navigate('/dashboard');
                                    } else {
                                        // pushActivity(EActivity.LAST_STEP);
                                        navigate('/lastStep');
                                    }
                                }
                            }}
                            onBack={() => setView("login")}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
});
