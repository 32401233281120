import {nativeApp} from "./webToApp";
import {appListener, isMobileApp} from "../webviewUtils";
import moment from "moment";

export const isAndroidApp = () => !!nativeApp;

export const iLog = (msg) => nativeApp.log(msg);

export const getValueFromAppStorage = (key) => nativeApp.getValue(key);

export const setValueToAppStorage = (key, value) => nativeApp.setValue(key, value);

export const getDateFromNativePicker = () => {
    return new Promise(resolve => {
        appListener("datePicker", (str) => {
            const [day, month, year] = str.split(",").map(x => parseInt(x));
            console.log(`Date str: ${str}`);
            resolve(moment(new Date(year, month, day)));
        });
        nativeApp.showDatePickerDialog();
    });
}

export const checkAndRequestPermissionsV2 = async () => {
    if (!isMobileApp()) {
        return false;
    }
    if (nativeApp.checkIfPermissionsGranted()) {
        return true;
    }
    await new Promise(resolve => {
        appListener("onChangePermissions", resolve);
        nativeApp.askForPermissions();
    });
    return nativeApp.checkIfPermissionsGranted();
}

export const downloadVoucher = (downloadUrl, fileName) => new Promise(resolve => {
    appListener("fileDownloaded", resolve);
    nativeApp.downloadPdf(downloadUrl, fileName);
});

export const printVoucherInApp = (downloadUrl) =>  new Promise(resolve => {
    appListener("fileDownloadedForPrint", resolve);
    nativeApp.printPdfFromUrl(downloadUrl);
});

export const createWhatsappIntent = (msg, phone) => {
    nativeApp.sendWhatsAppMessage(msg, phone);
}

export const hideAppSplash = () => {
    nativeApp.hideSplash();
}